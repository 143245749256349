<!-- 
 * @description:应急定制机构入驻
 * @fileName: yj_settled.vue 
 * @author:yr 
 * @date: 2022-09-22 
!-->
<template>
	<div class="wdn-settled-main">
		<div class="wd-settled">
			<div class="settled-header">
				<p>入驻申请</p>
			</div>
			<el-form ref="myForm" :label-position="'left'" :rules="rules" label-width="162px" :model="formData" @submit.native.prevent>
				<el-form-item label="企业名称：" prop="name" class="hasTip">
					<el-input v-model="formData.name" size="small" placeholder="请输入企业名称"></el-input>
				</el-form-item>
				<p class="formItemTip" style="color:#999;font-size:12px;">输入的名称与提交的营业执照副本上的名称保持一致</p>
				<el-form-item label="企业类型:" required>
					<el-select v-model="formData.enterType" size="small" placeholder="请选择" style="width:100%">
						<el-option v-for="item in institutionsArr" :key="item.value" :label="item.name" :value="item.value"> </el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="联系人：" prop="userName">
					<el-input v-model="formData.userName" size="small" :maxlength="30" placeholder="请输入联系人姓名"></el-input>
				</el-form-item>
				<el-form-item label="联系电话：" prop="phone" class="hasTip" style="margin-bottom:8px">
					<el-input v-model.trim="formData.phone" size="small" placeholder="请输入手机号"></el-input>
				</el-form-item>
				<div class="authCode formItemTip">
					<el-input v-model="authCode" size="small" placeholder="请输入验证码" style="width:412px"></el-input>
					<el-button v-if="Countdown" class="getcodeBtn getcodeBtnGray" type="primary" size="small" @click="getAuthCode">{{
						Countdown + 's'
					}}</el-button>
					<el-button v-else-if="isFirstGetAuthcode" class="getcodeBtn" type="primary" size="small" @click="getAuthCode">获取验证码</el-button>
					<el-button v-else class="getcodeBtn" type="primary" size="small" @click="getAuthCode">重新获取</el-button>
				</div>

				<el-form-item label="所属上级：" class="needRequred" style="margin-bottom:10px">
					<el-row type="flex" justify="space-between">
						<el-col class="selectItem">
							<el-form-item prop="city" style="margin-bottom:0">
								<el-select v-model="formData.city" size="small" placeholder="请选择市" @change="handleCityChange">
									<el-option v-for="item in cityList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col class="selectItem">
							<el-form-item style="margin-bottom:0">
								<el-select
									v-model="formData.district"
									:disabled="!formData.city"
									@change="handleDistrictChange"
									clearable=""
									size="small"
									placeholder="请选择区县"
								>
									<el-option v-for="item in districtList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col class="selectItem">
							<el-form-item style="margin-bottom:0">
								<el-select v-model="formData.street" :disabled="!formData.district" size="small" clearable="" placeholder="请选择乡镇街道园区">
									<el-option v-for="item in streetList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form-item>
				<div class="formItemTip">
					<el-input v-model="formData.addressDetail" size="small" :maxlength="50" placeholder="请输入详细地址"></el-input>
				</div>

				<el-form-item label="统一社会信用代码：" prop="organCode" key="organCode">
					<el-input v-model="formData.organCode" size="small" placeholder="请输入社会统一信用代码"></el-input>
				</el-form-item>
				<el-form-item label="营业执照副本：" prop="businessLicenseImage">
					<el-upload
						v-loading="licenseImageUploadLoading"
						class="avatar-uploader"
						:action="action"
						:headers="headers"
						accept="image/jpg, image/jpeg, image/png"
						:show-file-list="false"
						:on-success="licenseImageSuccess"
						:before-upload="beforeLicenseImageUpload"
						:on-error="
							() => {
								licenseImageUploadLoading = false
							}
						"
					>
						<img v-if="formData.businessLicenseImage" :src="formData.businessLicenseImage" class="avatar" />
						<div v-else>
							<i class="el-icon-plus avatar-uploader-icon"></i>
							<p class="uploadTip">点击上传</p>
						</div>
						<div slot="tip" class="el-upload__tip">营业执照扫描件图片后上传，支持jpg、png格式，图片大小不超过5M。</div>
					</el-upload>
				</el-form-item>
			</el-form>
			<div class="settled-footer">
				<el-button type="primary" size="small" class="submitBtn" @click="submit">提交申请</el-button>
			</div>
		</div>
	</div>
</template>
<script>
import config from '@/config/url'
import { organCodeValidator } from './../../../utils/validator'
export default {
	name: 'formDialog',
	data() {
		const accountValidator = (rule, value, callback) => {
			const isPhone = /^1(?:3[0-9]|4[5-9]|5[0-9]|6[12456]|7[0-8]|8[0-9]|9[0-9])[0-9]{8}$/
			if (!isPhone.test(value)) {
				callback(new Error('请输入正确的手机号'))
			} else {
				callback()
			}
		}
		return {
			institutionsArr: [
				{
					name: '生产',
					value: 15,
				},
				{
					name: '经营（储存设施构成重大危险源）',
					value: 16,
                },
                {
					name: '经营（储存设施未构成重大危险源）',
					value: 17,
                },
                {
					name: '使用（取得安全使用许可证）',
					value: 18,
                },
                {
					name: '加油站',
					value: 19,
                },
                {
					name: '一般化工',
					value: 20,
				},
				{
					name: '医药生产',
					value: 21,
				},
			],
			licenseImageUploadLoading: false,
			action: config.API + '/resource/v1/resource/uploadPicture',
			headers: {
				token: localStorage.getItem('token'),
			},
			authCode: '',
			cityList: [],
			districtList: [],
			streetList: [],
			formData: {
				name: '',
				enterType: '',
				userName: '',
				phone: '',
				targetBranchId: '', //注册到目标机构下
				addressDetail: '',
				businessLicenseImage: '',
				organCode: '',
				city: '',
				district: '',
				street: '',
			},
			rules: {
				name: { required: true, message: '请输入企业名称', trigger: 'blur' },
				userName: { required: true, message: '请输入联系人姓名', trigger: 'blur' },
				city: { required: true, message: '请选择市', trigger: 'change' },
				organCode: [
					{ required: true, message: '请输入社会统一信用代码', trigger: 'blur' },
					{ validator: organCodeValidator, trigger: 'blur' },
				],
				phone: [
					{ required: true, message: '请输入手机号', trigger: 'blur' },
					{ validator: accountValidator, trigger: 'blur' },
				],
				businessLicenseImage: { required: true, message: '请上传营业执照副本', trigger: 'blur' },
			},
			isFirstGetAuthcode: true,
			Countdown: 0,
		}
	},
	mounted() {
        // 获取省市区机构
		this.findRegion(1, null)
	},
	methods: {
		findRegion(level, parentId) {
			let params = {
				parentId,
			}
			this.$api.whpBranch.findRegion(params).then((res) => {
				if (res.success && res.data) {
					if (level == 1) {
						this.cityList = res.data
					} else if (level == 2) {
						this.districtList = res.data
					} else if (level == 3) {
						this.streetList = res.data
					}
				}
			})
		},
		getAuthCode() {
			this.$refs.myForm.validateField('phone', (error) => {
				if (!error) {
					const data = { account: this.formData.phone, type: 2 }
					if (this.Countdown > 0) return
					this.Countdown = 60
					this.setCountdown()
					this.$api.usercenter.getAuthCodePublic({ data })
					this.isFirstGetAuthcode = false
				}
			})
		},
		setCountdown() {
			this.CountdownInterval = setInterval(() => {
				this.Countdown--
				if (this.Countdown <= 0) {
					clearInterval(this.CountdownInterval)
				}
			}, 1000)
		},
		beforeLicenseImageUpload(file) {
			if (!['image/jpeg', 'image/jpg', 'image/png'].includes(file.type)) {
				this.$message.error('请上传jpg、png格式的图片')
				return false
			}
			if (file.size / 1024 / 1024 > 5) {
				this.$message.error('图片大小不能超过5M')
				return false
			}
			this.licenseImageUploadLoading = true
		},
		licenseImageSuccess(res) {
			this.formData.businessLicenseImage = res.data
			this.licenseImageUploadLoading = false
			this.$refs.myForm.clearValidate('businessLicenseImage')
		},
		handleCityChange(id) {
			this.formData.district = ''
			this.findRegion(2, id)
		},
		handleDistrictChange(id) {
			this.formData.street = ''
			this.findRegion(3, id)
		},
		async submit() {
			try {
				await this.$refs.myForm.validate()
			} catch (error) {
				return
			}
			let data = { ...this.formData }
			data.targetBranchId = data.street || data.district || data.city //入驻到目标机构
			data.authCode = this.authCode
			this.$api.usercenter.saveSdyjBranchApply(data).then((res) => {
				if (res.success) {
					this.$alert('审核结果将以短信通知给您，请注意查收', '已提交', {
						confirmButtonText: '确定',
						center: true,
						customClass: 'messageBox',
						confirmButtonClass: 'dialog_confirmBtn',
						callback: () => {
							this.$refs.myForm.resetFields()
                            this.authCode = ''
                            this.formData.addressDetail = '';
						},
					})
				}
			})
		},
	},
}
</script>
<style lang="stylus" scoped>
@import '../css/settled.styl'
</style>
